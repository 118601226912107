export default {
  baseStyle: {
    maxW: "1200px",
  },
  variants: {
    "with-top-padding": {
      py: "2rem",
    },
  },
};
